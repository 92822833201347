<template>
  
  <v-container fluid>

<!--     <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "member.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="green lighten-1" dark class="mb-2" @click="exportData">{{ $t( "member.btn_export" ) }}</v-btn>
      <v-btn color="green lighten-1" dark class="mb-2" @click="deployToScanner">{{ $t( "member.btn_deploy" ) }}</v-btn>
      <v-btn color="green lighten-1" dark class="mb-2" @click="removeFromScanner">{{ $t( "member.btn_remove" ) }}</v-btn>
    </v-toolbar> -->

    <v-toolbar flat color="white">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Reservation Actions
          </v-btn>
        </template>

        <v-list>
           <v-list-item link @click="sendKeyInfo">
            <v-list-item-icon>
              <v-icon color="primary">key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send Key Info</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

           <v-list-item link @click="sendReminder">
            <v-list-item-icon>
              <v-icon color="primary">notification_important</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send Reminder</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="exportData">
            <v-list-item-icon>
              <v-icon color="primary">download</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_export') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="deployToScanner">
            <v-list-item-icon>
              <v-icon color="primary">video_camera_front</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_deploy') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="removeFromScanner">
            <v-list-item-icon>
              <v-icon color="primary">videocam_off</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('member.btn_remove') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-toolbar flat color="white">
      <v-toolbar-items>
        <v-layout align-center justify-space-between row fill-height>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.reservationCode"
              :label="$t('reservation.table_reservation_code')"
              disabled
            ></v-text-field>
          </v-flex>
<!--           <v-flex grow xs1>
            <v-text-field
              :value="reservation.bookingId"
              :label="$t('reservation.table_booking_id')"
              disabled
            ></v-text-field>
          </v-flex> -->
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.guestName"
              :label="$t('reservation.table_guest')"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.memberCount"
              :label="$t('reservation.table_member_count')"
              disabled
            ></v-text-field>
          </v-flex>
<!--           <v-flex grow xs1>
            <v-text-field
              :value="reservation.phoneNo"
              :label="$t('reservation.table_phone_no')"
              disabled
            ></v-text-field>
          </v-flex> -->
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.checkInDate"
              :label="$t('reservation.table_checkin_date')"
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex grow xs1>
            <v-text-field
              :value="reservation.checkOutDate"
              :label="$t('reservation.table_checkout_date')"
              disabled
            ></v-text-field>
          </v-flex>
<!--           <v-flex grow xs12>
            <v-chip v-if="reservation.lastRequestOn"
              :color="getColor(reservation.lastRequestOn, reservation.lastResponse)"
              dark
            >
              {{ reservation.lastRequestOn }}
            </v-chip>
          </v-flex>   
 -->          
            <v-flex grow xs2>
              <v-text-field
                :value="reservation.lastRequestOn"
                label="Last Deployment Request"
                :label="$t('reservation.table_last_request')"
                :background-color="getColor(reservation.lastRequestOn, reservation.lastResponse)"
                disabled
              ></v-text-field>
          </v-flex>          
        </v-layout>
      </v-toolbar-items>
    </v-toolbar>

    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub1') }}</v-toolbar-title>
    </v-toolbar>

    <amplify-s3-album :path="idPath" :s3AlbumConfig="imageOption"></amplify-s3-album>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub2') }}</v-toolbar-title>
    </v-toolbar>
    
    <amplify-s3-album :path="facePath" :s3AlbumConfig="imageOption"></amplify-s3-album>
    <v-toolbar
      slot="header"
      class="mt-2"
      color="green lighten-1"
      dark
      dense
      flat
    >
      <v-toolbar-title class="subheading">{{ $t('member.title_sub3') }}</v-toolbar-title>
    </v-toolbar>
    <v-dialog v-model="dialog" max-width="800px">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('member.title_sub4') }}</span>
          </v-card-title>

          <v-card-text>
            <v-layout row fill-height>
              <v-flex xs6>            
                <file-upload 
                  extensions="jpg,jpeg" 
                  contentType="image/jpeg" 
                  :title="$t('member.drop_id')"
                  @filePicked="onIdFilePick"
                  name="dropId"
                  ref="dropId">
                </file-upload>
              </v-flex>
              <v-flex xs6>            
                <file-upload 
                  extensions="jpg,jpeg" 
                  contentType="image/jpeg" 
                  :title="$t('member.drop_face')"
                  @filePicked="onFaceFilePick"
                  name="dropFace"
                  ref="dropFace">
                </file-upload>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-text>
            <v-flex xs12>
              <img v-if="editedItem.thumb" :src="editedItem.thumb" width="100" height="auto" />
            </v-flex>
          </v-card-text>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.fullName" 
                    :label="$t('member.label_fullname')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.nationality" 
                    :label="$t('member.label_nationality')"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.idNumber" 
                    :label="$t('member.label_id_no')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.phoneNumber" 
                    :label="$t('member.label_phone_no')"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6>
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menuBirthDate"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.birthDate"
                        :label="$t('member.label_birth_date')"
                        prepend-icon="event"
                        readonly
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>                    
                    <v-date-picker v-model="editedItem.birthDate" no-title @input="menuBirthDate = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.gender" 
                    :label="$t('member.label_gender')"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.occupation" 
                    :label="$t('member.label_occupation')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.address" 
                    :label="$t('member.label_address')"
                  ></v-text-field>
                </v-flex>


                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.prevPlace" 
                    :label="$t('member.label_prev_place')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.nextDest" 
                    :label="$t('member.label_next_dest')"
                  ></v-text-field>
                </v-flex>


                <v-flex xs12 sm6 md6 lg6>
                  <v-select
                    v-if="reservation.smartKeyItems && reservation.smartKeyItems.length > 0"
                    v-model="editedItem.memberKeyItem"
                    :items="reservation.smartKeyItems"
                    :label="$t('member.label_room_number')"
                    item-text="roomCode"
                    return-object
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6>
                  <v-text-field
                    v-if="editedItem.memberKeyItem"
                    v-model="editedItem.memberKeyItem.keyInfo" 
                    :label="$t('member.label_key_info')"
                    disabled
                  ></v-text-field>
                </v-flex>
<!--                 <v-flex xs12 sm6 md6 lg6>
                  <v-text-field 
                    v-model="editedItem.keyInfo" 
                    :label="$t('member.label_key_info')"
                  ></v-text-field>

                  <v-select
                    v-model="editedItem.keyInfo"
                    :items="reservation.smartKeyItems"
                    :label="$t('member.label_key_info')"
                    item-text="keyInfo"
                    item-value="keyInfo"
                    clearable
                  ></v-select> 
                </v-flex> -->

              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDetail">Close</v-btn>
            <v-btn color="primary" text @click="saveDetail">Save</v-btn>
          </v-card-actions>                  


        </v-card>
      </v-form>
    </v-dialog>
    <v-data-iterator
      :items="members"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.memberNo"
            cols="12"
            xs="6"
            sm="4"
            md="3"
            lg="2"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">{{ $t('member.label_guest') }}: {{ item.fullName }}
                  <v-spacer></v-spacer>
                  <v-btn color="green lighten-1" fab small dark @click="openDetail(item)">
                    <v-icon>zoom_in</v-icon>
                  </v-btn>
              </v-card-title>

<!--   
              <v-divider></v-divider>

              <v-list dense>
                <v-list-item>
                  <v-list-item-content>{{ $t('member.label_valid_id') }}</v-list-item-content>
                  <v-switch v-if="item.lastUpdateOn" :input-value="item.idVerified" @change="verifyId(item)"> </v-switch>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>{{ $t('member.label_id_matches') }}</v-list-item-content>
                  <v-switch v-if="item.lastUpdateOn" :input-value="item.idMatches" @change="matchId(item)"></v-switch>
                </v-list-item> 

                <v-list-item>
                  <v-list-item-content>{{ $t('member.label_check_detail') }}</v-list-item-content>
                  <v-btn color="green lighten-1" fab small dark @click="openDetail(item)">
                    <v-icon>zoom_in</v-icon>
                  </v-btn>
                </v-list-item>
                
              </v-list>
-->
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>



<script>
import Papa from 'papaparse'
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog } from '@/utils'
import store from '@/store'
const FileUpload = () => import('@/components/FileUpload.vue')

export default {
  components: {
    FileUpload,
  },
  data: () => ({
    // rowsPerPageItems: [6, 8, 12],
    pagination: {
      rowsPerPage: 12
    },
    dialog: false,
    menuBirthDate: false,
    editedIndex: -1,
    editedItem: {
      fullName: '',
      gender: '',
      birthDate: '',
      address: '',
      idNumber: '',
      nationality: '',
      occupation: '',
      nextDest: '',
      memberKeyItem: {
        roomCode: '',
        keyInfo: ''
      }
    },
    idImgFile: {},
    faceImgFile: {},
  }),
  async beforeRouteEnter (to, from, next) {
    // console.log('store.state.host', store.state.host)

    const error = await store.dispatch('host/loadMembers').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  // async beforeRouteUpdate (to, from, next) {
  //   // console.log('store.state.host', store.state.host)

  //   const error = await store.dispatch('host/loadMembers').catch(error => {      
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //   } else {
  //     next()
  //   }
  // },
  computed: {
    imageOption () {
      return {
        identityId: this.reservation.identityId,
        level: 'protected',
      }
    },
    facePath () {
      return `${this.reservation.reservationCode}/face`
    },
    idPath () {
      return `${this.reservation.reservationCode}/id`
    },
    ...mapState({
      reservation: state => state.host.reservation,
      members: state => state.host.members,
      listingName: state => state.host.listing.listingName,
      scannerInstallations: state => state.scanner.scannerInstallations,
      reservationRooms: state => state.host.reservationRooms,
      faceImgKeyCount: state => state.host.faceImgKeyCount,
    }),
    ...mapGetters('host', {
      allIdVerified: 'allIdVerified',
    })
  },
  methods: {
/*
    async verifyId (crtMember) {
        crtMember.idVerified = !crtMember.idVerified

        // if (!crtMember.idVerified) {
        //   crtMember.idMatches = false
        // }

        let error = await this.updateMember(crtMember).catch(e => {
          console.error('updateMember', e)
          return e
        })

        if (error) {
          CallDialog(error)
        } else {
          
          if (this.allIdVerified) {
            this.reservation.isIdVerified = true            
          } else {
            this.reservation.isIdVerified = false
          }

          error = await this.updateIdVerified(this.reservation).catch(e => {
            console.error('updateIdVerified', e)
            return e
          })

          CallDialog(error)
        }

        
    },
    async matchId (crtMember) {
        crtMember.idMatches = !crtMember.idMatches

        let error = await this.updateMember(crtMember).catch(e => {
          console.error('updateMember', e)
          return e
        })

        if (error) {
          CallDialog(error)
        } else {

          if (this.allIdVerified) {
            this.reservation.isIdVerified = true            
          } else {
            this.reservation.isIdVerified = false
          }

          error = await this.updateIdVerified(this.reservation).catch(e => {
            console.error('updateIdVerified', e)
            return e
          })

          CallDialog(error)
        }
    },
*/
    async saveDetail() {

      let error

      if (this.editedItem.lastUpdateOn) {
        error = await this.updateMember(this.editedItem).catch(e => {
          console.error('updateMember', e)
          return e
        })

        CallDialog(error)
      } else {
        error = await this.createMember(this.editedItem).catch(e => {
          console.error('createMember', e)
          return e
        })

        CallDialog(error)
      }

      if (!error) {
        await this.putImage({member: this.editedItem, idImgFile: this.idImgFile, faceImgFile: this.faceImgFile}).catch(e => {
          console.error('putImage', e)
          return e
        })

        if (this.allIdVerified) {
          this.reservation.isIdVerified = true            
        } else {
          this.reservation.isIdVerified = false
        }

        await this.updateIdVerified(this.reservation).catch(e => {
          console.error('updateIdVerified', e)
          return e
        })
      }

      // CallDialog(error)      
      this.$refs.dropId.clear()
      this.$refs.dropFace.clear()
      this.dialog = false
    },
    closeDetail() {
      this.$refs.dropId.clear()
      this.$refs.dropFace.clear()
      this.dialog = false
    },
    openDetail(item) {
      if (this.$refs.dropId) {
        this.$refs.dropId.clear()
      }

      if (this.$refs.dropFace) {
        this.$refs.dropFace.clear()
      }

      console.log('openDetail', item)
      this.editedIndex = this.members.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    exportData() {
      const exportJson = this.members.map((member) => {
        return {
          "フルネーム": member.fullName,
          "性別": member.gender,
          "生年月日": member.birthDate,
          "住所": member.address,
          "電話番号": member.phoneNumber,
          "身分証明書番号": member.idNumber,
          "国籍": member.nationality,
          "到着日付": this.reservation.checkInDate,
          "出発日時": this.reservation.checkOutDate,
          "前泊地": member.prevPlace,
          "行先地": member.nextDest,
          "ビル名": this.listingName,
          "室名": this.roomCode,
          "職業": member.occupation
        }
      })

      console.log('exportJson', exportJson)

      const csv = Papa.unparse(exportJson)

      const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
      let csvURL
      if (navigator.msSaveBlob) {
          csvURL = navigator.msSaveBlob(csvData, 'download.csv')
      } else {
          csvURL = window.URL.createObjectURL(csvData)
      }
      const tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', 'download.csv')
      tempLink.click()
    },
    async onIdFilePick(file) {
      console.log('onIdFilePick', file)

      this.idImgFile = file

    },
    async onFaceFilePick(file) {
      console.log('onFaceFilePick', file)
      
      this.faceImgFile = file

    },
    async sendKeyInfo() {
      await this.notifyGuest({reservation: this.reservation, messageType: 'KEYINFO'})
    },
    async sendReminder() {
      await this.notifyGuest({reservation: this.reservation, messageType: 'REMINDER'})
    },
/*
    async validateRooms() {
      await this.getScannerInstallations({listingId: this.listingId})

      if (this.scannerInstallations.size == 0) {
        throw new Error('Scanner not found!')
      }

      await this.getReservationRooms()

      if (this.reservationRooms.size == 0) {
        throw new Error('Member of the reservation not found!')
      }

      if (this.faceImgKeyCount < this.reservation.memberCount) {
        throw new Error('Face image of each member is required!') 
      }

      this.reservationRooms.forEach(room => {
        if (!this.scannerInstallations.has(room)) {
          throw new Error("The reservation's room information does not match the scanner's room information!")
        }
      })


    },
*/    
    async removeFromScanner() {
      try {
        const cancelResult = await this.resetScannerDeployment({
          reservationCode: this.reservation.reservationCode, 
          listingId: this.reservation.listingId
        })

        cancelResult.lastUpdateOn = this.reservation.lastUpdateOn

        await this.updateReservationStatus(cancelResult)

      } catch(err) {
        console.error('dispatchScannerDeployment', err)
        CallDialog(err)
        throw err
      }


    },    
    async deployToScanner() {

      try {

        await this.validateScanners()

        await this.validateFaceImgs()

        const deployResult = await this.dispatchScannerDeployment({
          reservationCode: this.reservation.reservationCode, 
          listingId: this.reservation.listingId
        });
        
        deployResult.lastUpdateOn = this.reservation.lastUpdateOn

        await this.updateReservationStatus(deployResult)

      } catch(err) {
        console.error('dispatchScannerDeployment', err)
        CallDialog(err)
        throw err
      }

    },
    getColor (lastRequestOn, lastResponse) {
      if (lastRequestOn == lastResponse) {
        return 'green'
      } else {
        return 'orange'
      }        

    },
    ...mapActions({
      loadMembers: 'host/loadMembers',
      updateMember: 'host/updateMember',
      createMember: 'host/createMember',
      putImage: 'host/putImage',
      validateFaceImgs: 'host/validateFaceImgs',
      validateScanners: 'scanner/validateScanners',
      dispatchScannerDeployment: 'scanner/dispatchScannerDeployment',
      resetScannerDeployment: 'scanner/resetScannerDeployment',
      updateReservationStatus: 'host/updateReservationStatus',
      updateIdVerified: 'host/updateIdVerified',
      notifyGuest: 'host/notifyGuest',
    })
  },
}
</script>


<style>

#app .amplify-album { 
  width: 100%;
  margin: 0 auto;
  padding: 0.1em;
  border-radius: 0px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}

#app .amplify-album .amplify-album-container { 
  width: 100%;
  margin: 0 auto;
  padding: 0.1em;
}

#app .amplify-album .amplify-album-container .amplify-image {
  width: 8.33%;
  margin: 0 auto;
  padding: 0.1em;
  border-radius: 6px;
  border: 2px solid var(--color-white);
  cursor: pointer;
}
</style>